import axios from 'axios';
import { getServerUrl } from '@/js/gen.js';
import { environment } from '@/js/environment.js';


export default {

    get url() {
        const url = `${environment.baseApiUrl}/ws/CadastroInfoNext.asmx/`;
        console.log(`Server URL: ${url}`);
        return url;
    },

    //get url() {
    //    let serverType = localStorage.serverType || 'dev';
    //    let url = getServerUrl(serverType) + '/ws/CadastroInfoNext.asmx/';
    //    console.log(`Server URL: ${url}`);
    //    return url;
    //},

    //url: g.siteBackend + '/ws/CadastroInfoNext.asmx/',
    KeyWS: localStorage.k,
    async GetItensMarkupComercialModels(cmodel) {
        try {
            const res = await axios.get(this.url + 'GetItensMarkupComercialModels', {
                params: {
                    cmodel: cmodel
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async GetCustomizacaoComercial(cmodel, cod) {
        try {
            const res = await axios.get(this.url + 'GetCustomizacaoComercial', {
                params: {
                    cmodel: cmodel,
                    cod: cod
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },

    async GetVariante(codvar) {
        try {
            const res = await axios.get(this.url + 'GetVariante', {
                params: {
                    codvar: codvar
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async GetComercialModels() {
        try {
            const res = await axios.get(this.url + 'GetComercialModels', {                
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async GetComercialModelsByUser() {
        try {
            const res = await axios.get(this.url + 'GetComercialModelsByUser', {
                params: {
                    _uid: localStorage.k
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async GetItensCoefComercialModels(cmodel) {
        try {
            const res = await axios.get(this.url + 'GetItensCoefComercialModels', {
                params: {
                    cmodel: cmodel
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async SaveItensMarkupComercialModel(ItensMarkup, cmodel) {
        try {
            const res = await axios.post(
                this.url + 'SaveItensMarkupComercialModel',
                {
                    ItensMarkup: JSON.stringify(ItensMarkup),
                    cmodel: cmodel
                },
                {
                    headers: { 'KeyWS': localStorage.k }
                }
            );
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async SaveItensCoefComercialModel(ItensCoef, cmodel) {
        try {
            const res = await axios.post(
                this.url + 'SaveItensCoefComercialModel',
                {
                    ItensCoef: JSON.stringify(ItensCoef),
                    cmodel: cmodel
                },
                {
                    headers: { 'KeyWS': localStorage.k }
                }
            );
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async SaveCustomizacaoComercial(cmodel, cod, customizacaoComercial) {
        try {
            const res = await axios.post(
                this.url + 'SaveCustomizacaoComercial',
                {
                    cmodel: cmodel,
                    cod: cod,
                    customizacaoComercial: JSON.stringify(customizacaoComercial),
                },
                {
                    headers: { 'KeyWS': localStorage.k }
                }
            );
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async GetConfigGerais() {
        try {
            const res = await axios.get(this.url + 'GetConfigGerais', {
                params: {
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            //console.log(error);
            return 'ERRO:' + error;
        }
    },
    async SaveConfigGerais(configs) {
        try {
            const res = await axios.get(this.url + 'SaveConfigGerais', {
                params: {
                    configs: JSON.stringify(configs)
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async SaveItensCoef(ItensCoef) {
        try {
            const response = await axios.post(this.url + 'SaveItensCoef', {
                ItensCoef: JSON.stringify(ItensCoef),
            }, {
                headers: {
                    'KeyWS': localStorage.k,
                    //'Content-Type': 'application/json', // Defina o tipo de conteúdo como JSON, se necessário
                },
            });
            //console.log(response);
            return response.data;
        } catch (error) {
            return 'ERRO: ' + error;
        }
    },

    async GetLojas() {
        try {
            const res = await axios.get(this.url + 'GetLojas', {
                params: {
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async GetUsuarios() {
        try {
            const res = await axios.get(this.url + 'GetUsuarios', {
                params: {
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async GetItensCoef() {
        try {
            const res = await axios.get(this.url + 'GetItensCoef', {
                params: {
                },
                headers: { 'KeyWS': localStorage.k }
            });
            //console.log(res.data);
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
 
    async SaveLoja(loja) {
        try {
            const res = await axios.post(this.url + 'SaveLoja', {
                loja: JSON.stringify(loja),
            }, {
                headers: {
                    'KeyWS': localStorage.k,
                    //'Content-Type': 'application/json', // Defina o tipo de conteúdo como JSON, se necessário
                },
            });
            //console.log(response);
            return res.data;
        } catch (error) {
            return 'ERRO: ' + error;
        }
    },

    //async SaveLoja(loja) {
    //    try {
            
    //        const res = await axios.post(this.url + 'SaveLoja', {
    //            params: {
    //                loja: JSON.stringify(loja)
    //            },
    //            headers: { 'KeyWS': localStorage.k }
    //        });
    //        return res.data;
    //    } catch (error) {
    //        return 'ERRO:' + error;
    //    }
    //},
    async GetLoja(cod) {
        try {
            const res = await axios.get(this.url + 'GetLoja', {
                params: {
                    cod:cod
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async SaveUsuario(usuario) {
        try {

            const res = await axios.get(this.url + 'SaveUsuario', {
                params: {
                    usuario: JSON.stringify(usuario)
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async GetUsuario(cod) {
        try {
            const res = await axios.get(this.url + 'GetUsuario', {
                params: {
                    cod: cod
                },
                headers: { 'KeyWS': localStorage.k }
            });
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    }
}