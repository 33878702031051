const isDev = !window.location.href.includes('cinexms.3cad.com');

export const environment = {
    
    serverType: isDev ? 'dev' : 'production',
    baseApiUrl: isDev
        ? 'https://cinexms.d-next.it'
        : 'https://cinexmsprod.d-next.it',
};

console.log(`Environment: ${environment.serverType}`);
