import { createApp } from 'vue'
import App from './App.vue'
//import 'popper.js';
//import 'jquery';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle';
import router from './router';
import '@/css/stylegeral.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core'/* import the fontawesome core */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'/* import font awesome icon component */
import { faPlus, faSort, faDownload, faFileImage, faList, faTags, faTrash, faSave, faPlusCircle, faSignOut, faTableList, faThLarge, faStore, faUsers, faCogs, faFlask, faSliders, faAnglesRight, faCircleUser, faChevronRight, faChevronLeft, faFileLines, faTrashCan, faMagnifyingGlass, faPercent, faFilePdf, faCircleNotch, faFileCode, faCopy, faFilePen, faEye, faGlobe, faCodeCompare, faCode, faCircleInfo } from '@fortawesome/free-solid-svg-icons'/* import specific icons */
library.add(faPlus, faSort, faDownload, faFileImage, faList, faTags, faTrash, faSave, faPlusCircle, faSignOut, faTableList, faThLarge, faStore, faUsers, faCogs, faFlask, faSliders, faAnglesRight, faCircleUser, faChevronRight, faChevronLeft, faFileLines, faTrashCan, faMagnifyingGlass, faPercent, faFilePdf, faCircleNotch, faFileCode, faCopy, faFilePen, faEye, faGlobe, faCodeCompare, faCode, faCircleInfo)/* add icons to the library */

import sideBarMenu from './components/sideBarMenu.vue';
//import Sidebar from './components/sidebar.vue'
import ButtonsTop from './components/ButtonsTop.vue';
import Login from './components/login.vue'
import vpinputcheckbox from './components/inputs/vp-inputcheckbox.vue';
import vpinputdate from './components/inputs/vp-inputdate.vue';
import vpinputtext from './components/inputs/vp-inputtext.vue';
import vpselect from './components/inputs/vp-select.vue';
import vpselectlimits from './components/inputs/vp-selectlimits.vue';
import vpMessage from './components/inputs/vp-message.vue';
const app = createApp(App);

var siteBackend = 'https://cinexms.d-next.it';

app.component('VueDatePicker', VueDatePicker);
app.component('Login', Login);
//app.component('Sidebar', Sidebar);
app.component('sideBarMenu', sideBarMenu)
app.component('ButtonsTop', ButtonsTop)
app.component('vp-inputcheckbox', vpinputcheckbox);
app.component('vp-inputdate', vpinputdate);
app.component('vp-inputtext', vpinputtext);
app.component('vp-select', vpselect);
app.component('vp-selectlimits', vpselectlimits);
app.component('vp-message', vpMessage);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router).mount('#app');


