<template>
    <div>

        <div class="d-lg-flex half">
            <div id="bgLogin" class="bg order-2 order-md-1"></div>
            <div class="contents order-1 order-md-2">

                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-8">
                            <!--<div class="row">-->

                            <div class="d-flex justify-content-md-center text-center mt-sm-3">
                                <img id="imgTitulo" src="../assets/logo.png" />
                                <img id="imgTitulo2" src="../assets/cinex.png" />
                            </div>

                            <h3 class="my-5">Mediation server</h3>

                            <div class="col-sm-2" v-if="this.showMessage === 1"></div>
                            <div :class="'alert alert-'+ this.alertStatus " v-if="this.showMessage === 1" role="alert">{{this.alertMsg}}</div>
                            <div class="col-sm-2" v-if="this.showMessage === 1"></div>

                            <div class="form-group mb-3">
                                <!--<select v-model="serverType" class="form-select form-select-md">
        <option disabled selected value="">Selecione o tipo de servidor</option>
        <option value="production">Produção</option>
        <option value="dev">Dev</option>
    </select>-->
                                <div v-if="serverType">
                                    <p v-if="serverType === 'dev'">Servidor: <b>Desenvolvimento</b></p>
                                    <p v-else>Servidor: <b>Produção</b></p>
                                </div>
                            </div>

                            <div class="form-group first mb-3">
                                <label>ID:</label>
                                <input type="text" class="form-control" v-model="UserID" />
                            </div>
                            <div class="form-group first mb-3">
                                <label>Usuário:</label>
                                <input type="text" class="form-control" v-model="UserName" />
                            </div>

                            <div class="form-group last mb-3">
                                <label>Senha:</label>
                                <input type="password" class="form-control" v-model="Password" @keyup.enter="DoLog()" />
                            </div>
                            <div class="d-flex mb-5 align-items-center">
                                <button class="btn btn-block" id="botaoLogin" @click="DoLog()"><font-awesome-icon v-if="loading" icon="fa-solid fa-circle-notch" size="lg" spin /> ACESSAR</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <footer id="rodape">
                    <p>Powered by <a href="https://www.proidea.com.br">Proidea</a></p>
                </footer>
            </div>
        </div>

    </div>

</template>
<script>
    import ws from '@/js/WSAuth.js';
    import { environment } from '@/js/environment.js';

    export default {
        data() {
            return {
                serverType: '',
                currentServer: '',
                UserID: '',
                UserName: '',
                Password: '',
                alertStatus: '',
                alertMsg: '',
                showMessage: 0,
                loading: false,
                www: '',
            }
        },
        
        created: function () {
            //const hash = window.location.hash;
            //const urlParams = new URLSearchParams(hash.substring(hash.indexOf('?')));
            //const basebk = urlParams.get('basebk');
            //if (basebk === 'dev') {
            //    this.serverType = 'dev';
            //} else if (basebk === 'prod') {
            //    this.serverType = 'production';
            //}
            this.serverType=environment.serverType;

        },
        watch: {
            serverType(newVal) {
                localStorage.serverType = newVal;
            }
        },
        methods: {
            alerta(result) {
                this.alertMsg = this.www;
                if (typeof this.www === 'string' && result.includes('ERRO')) {
                    this.alertStatus = 'danger';
                } else {
                    this.alertStatus = 'success';
                }
                this.showMessage = 1;
            },
            DoLog() {
                this.loading = true;
                ws.Auth2(this.UserID, this.UserName, this.Password)
                    .then(result => {
                        //console.log(result);
                        this.www = result._UID
                        if (typeof this.www === 'string' && this.www.includes("ERRO")) {
                            this.resultStatus = 'error';
                            this.resultMessage = result;
                            this.alerta(this.www);
                            this.loading = false;
                            //console.log('qfcwe', result._UID);
                        } else {
                            this.handleSuccess(result);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        this.loading = false;
                    });
            },            

            handleSuccess(result) {
                setTimeout(() => {
                    this.currentServer = this.serverType;
                    this.loading = false;
                    localStorage.k = result._UID;
                    localStorage.userPerm = result.Group
                    console.log('serv', this.currentServer);
                    this.$router.push({ name: "confGerais" });
                    if (localStorage.userPerm === '998') {
                        this.$router.push({ name: "consultaDados"})
                    }
                }, 3500);
            }
            //DoLog() {
            //    var vm = this;
            //    this.loading = true;
            //    ws.Auth(this.UserID, this.UserName, this.Password).then((result) => {
            //        //console.log(result);
            //        if (result.includes("ERRO")) {
            //            this.resultStatus = 'error'
            //            this.resultMessage = result
            //            this.alerta(result);
            //            this.loading = false;                        
            //        }
            //        else {
            //            setTimeout(() => {
            //                this.currentServer = this.serverType;
            //                this.loading = false;
            //                localStorage.k = result;
            //                console.log('serv', this.currentServer)
            //                vm.$router.push({ name: "confGerais" });
            //            }, 3500)                        
            //        }
            //    });
            //},
        },
        
    }
</script>

<style scoped>
    button svg{
        border: none;
        margin-right: 10px;
    }
    #imgTitulo {
        width: 80px;
    }

    #imgTitulo2 {
        width: 150px;
    }

    footer {
        position: fixed;
        bottom: 0px;
        right: 22px;
    }

        footer p {
            font-size: 14px;
        }

    #botaoLogin {
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: #c75036;
        color: white;
    }

        #botaoLogin:hover {
            color: #c75036;
            border: 1px solid #c75036;
            background-color: transparent;
        }

    #bgLogin {
        background: url("../assets/Pattern_Cinza.png");
        opacity: 0.4;
    }

    .half, .half .container > .row {
        height: 100vh;
        min-height: 700px;
    }

    @media (max-width: 991.98px) {
        .half .bg {
            height: 125px;
        }
    }

    .half .contents {
        background: #f6f7fc;
    }

    .half .contents, .half .bg {
        width: 50%;
        -webkit-box-shadow: -7px 0px 10px -1px rgba(0,0,0,0.75);
        -moz-box-shadow: -7px 0px 10px -1px rgba(0,0,0,0.75);
        box-shadow: -7px 0px 10px -1px rgba(0,0,0,0.75);
    }

    @media (max-width: 1199.98px) {
        .half .contents, .half .bg {
            width: 100%;
        }
    }

    .half .contents .form-control, .half .bg .form-control {
        border: none;
        -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 54px;
        background: #fff;
    }

        .half .contents .form-control:active, .half .contents .form-control:focus, .half .bg .form-control:active, .half .bg .form-control:focus {
            outline: none;
            -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }

    .half .bg {
        background-size: cover;
        background-position: center;
    }

    .half a {
        color: #888;
        text-decoration: underline;
    }

    .half .btn {
        height: 54px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 576px) {
        .half, .half .container > .row {
            height: auto;
            min-height: auto;
        }

        .mt-sm-3 {
            margin-top: 20px;
        }

        .app {
            display: block;
        }
    }
</style>