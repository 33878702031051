import { createRouter, createWebHashHistory } from 'vue-router';
import { environment } from '@/js/environment.js';

const routes = [
    { path: '/', component: () => import('./components/login.vue') },
    { path: '/testeFoccoLoja', name: 'testeFoccoLoja', component: () => import('./components/testeFoccoLoja.vue') },
    { path: '/home', name: 'home', component: () => import('./components/home.vue') },
    { path: '/listaTabelas', name: 'listaTabelas', component: () => import('./components/listaTabelas.vue') },
    { path: '/itensCoeficientes', name: 'itensCoeficientes', component: () => import('./components/itensCoeficientes.vue') },
    { path: '/consultaDados', name: 'consultaDados', component: () => import('./components/consultaDados.vue') },
    { path: '/xmlfocco', name: 'xmlPage', component: () => import('./components/xmlPage.vue') },
    { path: '/codeeditor', name: 'codeEditor', component: () => import('./components/codeEditor.vue') },
    { path: '/tabelas', name: 'tabelas', component: () => import('./components/tabelas.vue'), props: true },
    { path: '/clientes', name: 'clientes', component: () => import('./components/clientes.vue') },
    { path: '/lojas', name: 'lojas', component: () => import('./components/lojas.vue') },
    { path: '/listaLojas', name: 'listaLojas', component: () => import('./components/listaLojas.vue') },
    { path: '/confGerais', name: 'confGerais', component: () => import('./components/confGerais.vue') },
    { path: '/usuarios', name: 'usuarios', component: () => import('./components/usuarios.vue') },
    { path: '/listaUsuarios', name: 'listaUsuarios', component: () => import('./components/listaUsuarios.vue') },
    { path: '/teste', name: 'teste', component: () => import('./components/teste.vue') },
    { path: '/foccoerp', name: 'foccoerp', component: () => import('./components/foccoerp.vue') },
    { path: '/coeficientesComercial', name: 'coeficientesComercial', component: () => import('./components/coeficientesComercial.vue') },
    { path: '/customizacaoComercial', name: 'customizacaoComercial', component: () => import('./components/customizacaoComercial.vue') },
    { path: '/listaComercial', name: 'listaComercial', component: () => import('./components/listaComercial.vue') },
    { path: '/markupComercial', name: 'markupComercial', component: () => import('./components/markupComercial.vue') },
    { path: '/listaMarkupComercial', name: 'listaMarkupComercial', component: () => import('./components/listaMarkupComercial.vue') },
    { path: '/visualizadorjson', name: 'visualizadorjson', component: () => import('./components/visualizadorJson.vue') },
    { path: '/comparadorprojeto', name: 'comparadorprojeto', component: () => import('./components/comparadorProjeto.vue') },
    { path: '/assistencia', name: 'assistencia', component: () => import('./components/assistencia.vue') },
    { path: '/selecao', name: 'selecao', component: () => import('./components/iframe/selecaoitensproduto.vue'), meta: { hideSidebar: true } },
    { path: '/:pathMatch(.*)*', component: () => import('./components/PageNotFound') }
];



const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    console.log(`Navigating in ${environment.serverType} environment`);
    next();
});
//history: createWebHistory(process.env.BASE_URL),

//router.beforeEach((to, from, next) => {
//    const blockedRoutes = ['foccoerp', 'consultaDados'];
//    if (blockedRoutes.includes(to.name)) {
//        if (localStorage.userPerm !== '999') {
//            next('/not-authorized');
//        } else {
//            next();
//        }
//    } else {
//        next();
//    }
//});

export default router;
