<template>
    <aside :class="`${isExpanded ? 'is-expanded' : ''}`">
        <div class="logo d-flex align-items-center">
            <img src="../assets/logo.png" alt="Logo Cinex" />
            <h5>Bem vindo</h5>
        </div>

        <div class="menu-toggle-wrap">
            <!--<button class="menu-toggle" @click="toggleMenu"><font-awesome-icon icon="angles-right" /></button>-->
            <button class="menu-toggle" @click="toggleMenu">
                <font-awesome-icon icon="fa-solid fa-angles-right" class="sideicon" />
            </button>
        </div>

        <!-- <h3>Menu</h3> -->
        <div class="menu">
            <router-link v-if="userGroup==999" :to="{name:'confGerais'}" class="button">
                <font-awesome-icon icon="fa-solid fa-cogs" class="sideicon" />
                <span class="text">Configurações gerais</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'listaTabelas'}" class="button">
                <font-awesome-icon icon="fa-solid fa-table-list" class="sideicon" />
                <span class="text">Tabelas</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'assistencia'}" class="button">
                <font-awesome-icon icon="fa-solid fa-code" class="sideicon" />
                <span class="text">Assistência</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'visualizadorjson'}" class="button">
                <font-awesome-icon icon="fa-solid fa-code" class="sideicon" />
                <span class="text">JSON Focco Loja</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'comparadorprojeto'}" class="button">
                <font-awesome-icon icon="fa-solid fa-code-compare" class="sideicon" />
                <span class="text">Comparador de Projeto</span>
            </router-link>
            <router-link v-if="userGroup==999 && 1==0" :to="{name:'itensCoeficientes'}" class="button">
                <font-awesome-icon icon="fa-solid fa-percent" class="sideicon" />
                <span class="text">Itens Coeficiente (obsoleto)</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'coeficientesComercial'}" class="button">
                <font-awesome-icon icon="fa-solid fa-percent" class="sideicon" />
                <span class="text">Coeficiente Comercial</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'customizacaoComercial'}" class="button">
                <font-awesome-icon icon="fa-solid fa-percent" class="sideicon" />
                <span class="text">Customização Comercial</span>
            </router-link>
            <router-link v-if="userGroup==999 || userGroup==300" :to="{name:'markupComercial'}" class="button">
                <font-awesome-icon icon="fa-solid fa-percent" class="sideicon" />
                <span class="text">Markup Comercial</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'listaLojas'}" class="button">
                <font-awesome-icon icon="fa-solid fa-store" class="sideicon" />
                <span class="text">Lojas</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'listaUsuarios'}" class="button">
                <font-awesome-icon icon="fa-solid fa-users" class="sideicon" />
                <span class="text">Usuários</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'codeEditor'}" class="button">
                <font-awesome-icon icon="fa-solid fa-file-pen" class="sideicon" />
                <span class="text">Editor de fichas</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'consultaDados'}" class="button">
                <font-awesome-icon icon="fa-solid fa-file-code" class="sideicon" />
                <span class="text">Fichas</span>
            </router-link>
            <router-link v-if="userGroup==999" :to="{name:'foccoerp'}" class="button">
                <font-awesome-icon icon="fa-solid fa-file-lines" class="sideicon" />
                <span class="text">Focco Erp</span>
            </router-link>
        </div>

        <div class="flex"></div>

        <div class="menu">
            <div class="userMenu">
                <font-awesome-icon icon="fa-solid fa-circle-user" class="sideicon" />
                <span class="text">{{conf.MediationUserName}}</span>
            </div>
        </div>
        <div class="menu">
            <div class="userMenu">
                <font-awesome-icon icon="fa-solid fa-globe" class="sideicon" />
                <span class="text">Servidor: {{ serverType }}</span>
            </div>
        </div>
        <hr class="mb-3">
        <div class="menu">
            <router-link to="/" @click="clearLocalStorage()" class="button">
                <div id="linkSair"><font-awesome-icon icon="fa-solid fa-sign-out" class="sideicon" /><span class="text">Sair</span></div>
            </router-link>
        </div>
    </aside>
</template>

<script>
    import wq from '@/js/WSAuth.js';
    import ws from '@/js/WSCadastroInfoNext.js';
    import { ref } from 'vue'
    export default {
        name: 'sideBarMenu',
        components: {

        },
        data() {
            return {
                conf: {
                    MediationUserName: '',
                },
                serverType: localStorage.serverType || 'dev',
                //showItem: false,
                userGroup:0
            }
        },
        created: function () {
            ws.GetConfigGerais().then((result) => {
                if (result != "") {
                    this.conf = result;
                }
            });
            this.checkUser();
        },
        methods: {
            clearLocalStorage: function () {
                //localStorage.clear();
            },
            checkUser: function () {
                this.userGroup = parseInt(localStorage.userPerm);
               // this.showItem = localStorage.userPerm === '999';
                //this.showItemUser = true;
            }
        },

        //setup() {
        //    const isExpanded = ref(localStorage.getItem('isExpanded') === 'true')

        //    const toggleMenu = () => {
        //        isExpanded.value = !isExpanded.value
        //        localStorage.setItem('isExpanded', isExpanded.value)
        //    }


        //    return {
        //        isExpanded,
        //        toggleMenu
        //    }
        //},
        setup(_, { emit }) {
            /*const isExpanded = ref(true);*/
            const isMobile = window.innerWidth <= 576;
            const isExpanded = ref(!isMobile); // Defina como true se não for um dispositivo móvel

            const toggleMenu = () => {
                isExpanded.value = !isExpanded.value;
                localStorage.setItem('isExpanded', isExpanded.value);

                // Emitir um evento personalizado para notificar App.vue sobre a mudança
                emit('sidebar-toggled', isExpanded.value);
            };

            return {
                isExpanded,
                toggleMenu,
            };
        },

        
    }
</script>

<style scoped>
    aside {
        display: flex;
        flex-direction: column;
        background-color: var(--dark);
        color: var(--light);
        width: calc(2rem + 28px);
        overflow: hidden;
        min-height: 100vh;
        padding: 1rem;
        transition: 0.2s ease-in-out;
        position: fixed;
    }
    aside .menu .userMenu{
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        padding: 0.55rem 1rem;
    }
    aside .menu .userMenu:hover{
        color: inherit;
    }
    aside .menu .userMenu svg{
        cursor: inherit !important;
    }
    aside .menu .userMenu .text {
        color: var(--light);
        transition: 0.2s ease-in-out;
        /*opacity: 0;*/
    }
    aside .text{
        display:none;
    }

    aside.is-expanded .text{
        display: inline-block;
    }
    /*aside .button .userMenu .text{
        transition: opacity 0.3s ease-in-out;
    }*/
    aside .flex {
        flex: 1 1 0%;
    }

    aside .logo {
        margin-bottom: 1rem;
    }

    aside .logo img {
        width: 2rem;
    }

    aside .menu-toggle-wrap {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        position: relative;
        top: 0;
        transition: 0.2s ease-in-out;
    }

    aside .menu-toggle-wrap .menu-toggle {
        transition: 0.2s ease-in-out;
    }

    aside .menu-toggle-wrap .menu-toggle .sideicon {
        font-size: 1.4rem;
        color: var(--light);
        transition: 0.2s ease-out;
    }

    aside .menu-toggle-wrap .menu-toggle:hover .sideicon {
        color: var(--primary);
        transform: translateX(0.5rem);
    }

    aside h3,
    aside .button .text {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    aside h3 {
        color: var(--grey);
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    aside h5 {
        margin-left: 40px;
        margin-bottom: 0;
    }

    aside .menu {
        margin: 0 -1rem;
    }

    aside .menu .button {
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        padding: 0.55rem 1rem;
    }

    aside .menu .button .sideicon, aside .menu .userMenu .sideicon {
        font-size: 1.5rem;
        color: var(--light);
        transition: 0.2s ease-in-out;
    }

    aside .menu .button .text {
        color: var(--light);
        transition: 0.2s ease-in-out;
    }

    aside .menu .button:hover {
        background-color: var(--dark-alt);
    }

    aside .menu .button:hover .sideicon,
    aside .menu .button:hover .text {
        color: var(--primary);
    }

    aside .menu .button.router-link-exact-active {
        background-color: var(--dark-alt);
        border-right: 5px solid var(--primary);
    }

    aside .menu .button.router-link-exact-active .sideicon,
    aside .menu .button.router-link-exact-active .text {
        color: var(--primary);
    }

    aside .footer {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    aside .footer p {
        font-size: 0.875rem;
        color: var(--grey);
    }

    aside.is-expanded {
        width: var(--sidebar-width);
    }

    aside.is-expanded .menu-toggle-wrap {
        top: -2.7rem;
    }

    aside.is-expanded .menu-toggle-wrap .menu-toggle {
        transform: rotate(-180deg);
    }

    aside.is-expanded h3,
    aside.is-expanded .button .text {
        opacity: 1;
    }

    aside.is-expanded .button .sideicon, aside.is-expanded .userMenu .sideicon  {
        margin-right: 1rem;
        width: 30px;
    }

    aside.is-expanded .footer {
        opacity: 0;
    }

/**** MOBILE ****/
    @media (max-width: 576px) {
        
    
    
    }
    @media (max-width: 1024px) {
        aside {
            /*position: absolute;*/
            z-index: 99;
        }
    }



</style>